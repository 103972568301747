/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'url-polyfill';
import '@babel/polyfill';

require.context('images', true, /\.(png|jpg|jpeg|svg)$/);

// レビューモーダルの表示、クローズ

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.check-user').forEach((element) => {
    if (element.textContent.includes('としてのレビューを書く')) {
      element.addEventListener('click', (e) => {
        e.preventDefault(); // デフォルトのリンク動作を防ぐ
        const link = e.currentTarget.querySelector('a');
        const { urlName } = link.dataset;
        const modal = document.querySelector(`.review-modal-${urlName}`);
        if (modal) {
          modal.style.display = 'block';
        }
      });
    }
  });

  document.querySelectorAll('.review-modal-close').forEach((closeBtn) => {
    closeBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const modal = e.target.closest('.review-modal');
      if (modal) {
        modal.style.display = 'none';
      }
      Toastr.error('B2Bのレビューサイトのため、一般利用のレビューはご投稿いただけません。ご了承ください。');
    });
  });
});
